<template>
	<v-card>
		<v-dialog v-if="dialogShow" v-model="dialogShow" width="80%" persistent hide-overlay>
			<!-- <v-container> -->
			<v-card>
				<!-- <v-row style="margin: auto"> -->
                    <v-col cols="12">
                        <v-row style="margin: auto">
                            <v-col cols="10">
                                <v-textarea
                                    solo
                                    hide-details
                                    label="Transact SQL"
                                    v-model="transactSQL"
                                    @keydown="keydown()"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="2">
                                <v-row style="margin: auto">
                                    <v-col cols="12" style="margin-left: -30px;">
                                        <v-btn width="100%" style="text-transform: none" small color="info" @click="executeSQL()">
                                            Ejecutar
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" style="margin-top: -20px; margin-left: -30px;">
                                        <v-btn :disabled="disabled" width="100%" style="text-transform: none" small color="error" @click="donwloadData()">
                                            Download
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        
                    </v-col>
               <!--  </v-row> -->
                <v-row style="margin: auto">
					<v-col cols="12">
						<v-row style="margin: auto; margin-top: -20px">
							<v-col cols="12"
								><v-text-field
									v-model="search"
									append-icon="mdi-magnify"
									label="Buscar"
									single-line
									hide-details
								></v-text-field
							></v-col>
							<v-col cols="12" style="margin-top: -20px">
								<v-data-table
									:items="items"
									:headers="headers"
									:search="search"
									class="elevation-1 table"
									dense
								>
								</v-data-table>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card>
			<!-- </v-container> -->
		</v-dialog>
	</v-card>
</template>

<script>
	import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
	export default {
		components: {},
		data() {
			return {
				dialogShow: true,
				transactSQL: "",
				items: [],
				headers: [],
				search: "",
                disabled: true
			};
		},

		methods: {

            keydown(){
                if(this.transactSQL.length== 0){
                    this.disabled = true
                    this.items = []
                    this.headers = []
                }
            },
			executeSQL() {
				var obj = {
					TransactSQL: this.transactSQL,
				};

				_sQryConfigurationService
					.execuetSQL(obj, this.$fun.getUserID())
					.then((resp) => {
						if (resp.status == 200) {
							this.items = resp.data;

                            if(this.items.length > 0) {
                                this.disabled = false
                            }


							for (
								var i = 0;
								i < Object.keys(this.items[0]).length;
								i++
							) {
								this.headers.splice(i, i, {
									text: Object.keys(this.items[0])[i],
									value: Object.keys(this.items[0])[i],
									width: "150",
									sortable: false,
								});
							}
						}
					},
						(e) => {
							this.disabled = true
							this.items = []
							this.headers = []
						}
					);
			},

			donwloadData(){

				var obj = {
					TransactSQL: this.transactSQL,
				};


				_sQryConfigurationService
					.downloadexcelTransactSQL(obj, this.$fun.getUserID())
					.then(
						(r) => {
							if (r.status == 200) {
								this.$fun.downloadFile(
									r.data,
									this.$const.TypeFile.EXCEL,
									this.transactSQL
								);
							}
						}
					);

			}
		},
	};
</script>